<template>
  <div class="page_">
    <div class="navbar_">
      <div class="main_">
        <img src="../../assets/medilink/service/header.png" alt="" />
        <div class="text_">
          <p>增值服务</p>
        </div>
      </div>
      <navbar :showHeader="showHeader" :nowIndex="4"></navbar>
    </div>
    <div class="block_">
      <p class="tit_"><span>增值服务</span><span>7*24*365热线服务</span></p>
      <div class="con_ w1000">
        <div class="box_">
          <p>健康管理类</p>
          <div class="flex_">
            <div class="flex_i" v-for="item in service1">
              <img :src="item.imgUrl" alt="" />
              <span>{{ item.title }}</span>
              <div class="mask_">
                <span>{{ item.title }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="box_">
          <p>就医就诊类</p>
          <div class="flex_">
            <div class="flex_i" v-for="item in service2">
              <img :src="item.imgUrl" alt="" />
              <span>{{ item.title }}</span>
              <div class="mask_">
                <span>{{ item.title }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footerbar></footerbar>
    <sideBar v-show="showBar"></sideBar>
  </div>
</template>
<script>
import footerbar from "./components/footer.vue";
import navbar from "./components/navbar.vue";
import sideBar from "./components/sideBar.vue";
export default {
  data() {
    return {
      showHeader: false,
      height: 0,
      showBar: false,
      service1: [
        {
          imgUrl: require("../../assets/medilink/service/img1.png"),
          title: "健康档案管理"
        },
        {
          imgUrl: require("../../assets/medilink/service/img2.png"),
          title: "体检方案管理"
        },
        {
          imgUrl: require("../../assets/medilink/service/img3.png"),
          title: "健康教育"
        },
        {
          imgUrl: require("../../assets/medilink/service/img4.png"),
          title: "慢病管理"
        }
      ],
      service2: [
        {
          imgUrl: require("../../assets/medilink/service/img11.png"),
          title: "家庭医生咨询"
        },
        {
          imgUrl: require("../../assets/medilink/service/img22.png"),
          title: "就医绿色通道"
        },
        {
          imgUrl: require("../../assets/medilink/service/img33.png"),
          title: "二次诊疗意见"
        },
        {
          imgUrl: require("../../assets/medilink/service/img44.png"),
          title: "紧急救援"
        }
      ]
    };
  },
  mounted() {
    window.onscroll = () => {
      this.handleScroll();
    };
  },
  components: {
    footerbar,
    navbar,
    sideBar
  },
  methods: {
    handleScroll(e) {
      var top = Math.floor(
        document.body.scrollTop ||
          document.documentElement.scrollTop ||
          window.pageXOffset
      );
      this.height = top;
    }
  },
  watch: {
    height: {
      handler(newHeight, oldHeight) {
        if (newHeight > 400) {
          this.showBar = true;
          if (newHeight > oldHeight) {
            this.showHeader = false;
          } else {
            this.showHeader = true;
          }
        } else {
          this.showHeader = false;
          this.showBar = false;
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.page_ {
  padding: 0;
  background-color: #fff;
  p {
    margin: 0;
  }
  .navbar_ {
    position: relative;
    width: 100%;
    .main_ {
      width: 100%;
      position: relative;
      color: #fff;
      & > img {
        height: auto;
        width: 100%;
        object-fit: cover;
      }
      .text_ {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        margin: 0 auto;
        font-weight: bold;
        font-size: 33px;
        text-align: center;
      }
    }
  }

  .block_ {
    width: 100%;
    color: #000000;
    font-size: 11px;
    p {
      text-align: center;
    }
    .flex_ {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & > .tit_ {
      font-size: 14px;
      color: #666;
      padding: 50px 0;
      justify-content: center;
      display: flex;
      flex-direction: column;
      span:first-child {
        font-size: 20px;
        font-weight: bold;
        color: #000;
      }
    }
    .drop {
      width: 4px;
      height: 4px;
      background-color: #00a63c;
      display: inline-block;
      margin: 0 16px;
    }
    .bold_ {
      font-weight: bold;
    }
    .con_ {
      color: #333;
      font-size: 11px;
      &.w1000 {
        width: 1000px;
        margin: 0 auto;
        .box_ {
          margin-bottom: 40px;
          p {
            font-size: 15px;
            color: #000;
            text-align: left;
            padding-bottom: 14px;
          }
          .flex_ {
            justify-content: space-between;
            .flex_i {
              width: 223px;
              height: 175px;
              position: relative;
              cursor: pointer;
              img {
                width: 100%;
                height: 100%;
              }
              > span {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(0, 0, 0, 0.7);
                height: 32px;
                line-height: 32px;
                text-align: center;
                color: #fff;
                font-size: 14px;
              }
              &:hover {
                .mask_ {
                  display: flex;
                }
                > span {
                  display: none;
                }
              }
              .mask_ {
                display: none;
                background-color: rgba(0, 0, 0, 0.5);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                color: #fff;
                font-size: 14px;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
}
</style>
